<template>
  <div>
    <v-row>
      <v-col>
        <v-select
          :items="transaction_to_accounts"
          v-model="payload.transaction_to"
          item-text="account_no"
          item-value="id"
          label="Deposited in A/C(*)"
          placeholder="Deposited in A/C"
          color="#002441"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          :items="transaction_from_accounts"
          v-model="payload.transaction_from"
          item-text="account_no"
          item-value="id"
          label="Deposited From A/C(*)"
          color="#002441"
        ></v-select>
      </v-col>
      <v-col>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="payload.transfer_date"
              label="Deposited Date(*)"
              color="#002441"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="payload.transfer_date"
            @input="menu2 = false"
            class="ma-0"
            color="#002441"
            prev-icon="mdi-skip-previous"
            next-icon="mdi-skip-next"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-text-field
          label="Reference(*)"
          v-model="payload.reference_number"
          placeholder="Reference number"
          color="#002441"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-text-field
          label="Amount(*)"
          v-model.number="payload.amount"
          placeholder="0"
          type="number"
          color="#002441"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <div class="fileUpload">
          <span class="custom-para">Select Image</span>
          <input
            type="file"
            class="upload"
            ref="fileFromBankTransfer"
            v-on:change="handleFileUpload"
          />
        </div>
        <div class="pa-2" v-if="file_name">{{ file_name }}</div>
        <div class="pa-2" style="color: red" v-if="error">{{ error }}</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="#002441" elevation="0" @click="onSubmit"> Submit </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: [
    "deposite_type",
    "transaction_to_accounts",
    "transaction_from_accounts",
  ],
  data() {
    return {
      payload: {
        transfer_date: new Date().toISOString().substr(0, 10),
      },
      menu2: false,
      file_name: "",
      error : ''
    };
  },
  methods: {
    // Validate file extension
    validateFile(file) {
      if (/\.(jpe?g|png|gif)$/i.test(file.name) === false) return false;
      return true;
    },

    // Handle Image upload
    handleFileUpload() {
      // Validate file if it is an image
      let is_valid_file = this.validateFile(
        this.$refs.fileFromBankTransfer.files[0]
      );

      if (is_valid_file) {
        this.payload.image = this.$refs.fileFromBankTransfer.files[0];
        this.file_name = this.payload.image.name;
        this.error = "";
      } else {
        this.payload.image = ''
        this.file_name = "";
        this.error = "Please,upload an image.";
      }
    },

    // Submit the form
    onSubmit() {
      this.$emit("onSubmitForm", this.payload);
      this.payload = {
        transfer_date: new Date().toISOString().substr(0, 10),
      };
      this.file_name = "";
      this.error = "";
    },
  },
};
</script>

<style>
.fileUpload {
  position: relative;
  overflow: hidden;
  display: block;
  background-color: #f25f3a;
  height: 34px;
  width: 100%;
  line-height: 2.6;
  text-align: center;
  margin-top: 15px;
}

.custom-para {
  font-weight: 700;
  font-size: 15px;
  color: #fff;
}

.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  height: 100%;
  text-align: center;
}
</style>